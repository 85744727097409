import React from 'react';
import { graphql, HeadProps, PageProps } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

type Props = Queries.ProjectPageBySlugQuery;

const ProductTemplate = ({ data }: PageProps<Props>) => {
  const { markdownRemark: project } = data;

  return (
    <Layout>
      <article className="py-16">
        <div className="px-4 sm:px-6 lg:px-8">
          <header className="mx-auto mb-6 max-w-prose text-lg">
            {project?.frontmatter.title && (
              <h1 className="mb-8 mt-2 text-center text-3xl font-extrabold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                {project.frontmatter.title}
              </h1>
            )}
            {project?.frontmatter.description && (
              <p className="text-xl leading-8 text-gray-500">{project.frontmatter.description}</p>
            )}
          </header>
          {/* eslint-disable react/no-danger */}
          <section
            className="prose prose-lg prose-indigo mx-auto text-gray-500"
            dangerouslySetInnerHTML={{ __html: project?.html ?? '' }}
          />
          {/* eslint-enable react/no-danger */}
        </div>
      </article>
    </Layout>
  );
};

export const Head = ({ data }: HeadProps<Props>) => {
  const { markdownRemark: project } = data;

  return <SEO title={project?.frontmatter.title ?? ''} />;
};

export default ProductTemplate;

export const pageQuery = graphql`
  query ProjectPageBySlug($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
